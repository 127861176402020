import React, { useState } from "react";
// import { Link } from "react-router-dom";
import Form from '../../utilities/Forms'
import axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

const Reset = () => {
    const query = useQuery();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validate, setValidate] = useState({});
    const [showPassword, setShowPassword] = useState(false);

    const validateforgotPassword = () => {
        let isValid = true;

        let validator = Form.validator({
            password: {
                value: password,
                isRequired: true,
                minLength: 8
            },
            confirmPassword: {
                value: confirmPassword,
                isRequired: true,
                minLength: 8
            }
        });

        if (validator !== null) {
            setValidate({
                validate: validator.errors
            })

            isValid = false
        }
        return isValid;
    }

    const forgotPassword = async (e) => {
        e.preventDefault();

        const validate = validateforgotPassword();

        if (validate && query.get("token")) {
            if (password !== confirmPassword) {
                return alert("Password do not match.")
            }
            try {
                await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/auth/reset-password`, { token: query.get("token"), password: password })
                alert("Password is now resetted.")
            } catch (err) {
                alert("Something went wrong. Please check the expire date or try again.")
            }
            setValidate({});
            setPassword('');
            setConfirmPassword('');
        }
    }

    const togglePassword = (e) => {
        if (showPassword) {
            setShowPassword(false);
        } else {
            setShowPassword(true)
        }
    }

    return (
        <div className="row g-0 auth-wrapper">
            <div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
                <div className="auth-background-holder"></div>
                <div className="auth-background-mask"></div>
            </div>

            <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center">
                <div className="d-flex flex-column align-content-end">
                    <div className="auth-body mx-auto">
                        <p>Reset Password</p>
                        <div className="auth-form-container text-start">
                            <form className="auth-form" method="POST" onSubmit={forgotPassword} autoComplete={'off'}>
                            <div className="password mb-3">
                            <div className="input-group">
                                        <input type={showPassword ? 'text' : 'password'}
                                            className={`form-control ${validate.validate && validate.validate.password ? 'is-invalid ' : ''}`}
                                            name="password"
                                            id="password"
                                            value={password}
                                            placeholder="Password"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />

                                        <button type="button" className="btn btn-outline-primary btn-sm" onClick={(e) => togglePassword(e)} ><i className={showPassword ? 'far fa-eye' : 'far fa-eye-slash'} ></i> </button>

                                        <div className={`invalid-feedback text-start ${(validate.validate && validate.validate.password) ? 'd-block' : 'd-none'}`} >
                                            {(validate.validate && validate.validate.password) ? validate.validate.password[0] : ''}
                                        </div>
                                    </div>
                                    </div>

                                    <div className="password mb-3">
                            <div className="input-group">
                                        <input type={showPassword ? 'text' : 'password'}
                                            className={`form-control ${validate.validate && validate.validate.confirmPassword ? 'is-invalid ' : ''}`}
                                            name="confirmPassword"
                                            id="confirmPassword"
                                            value={confirmPassword}
                                            placeholder="Confirm Password"
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />

                                        <div className={`invalid-feedback text-start ${(validate.validate && validate.validate.password) ? 'd-block' : 'd-none'}`} >
                                            {(validate.validate && validate.validate.password) ? validate.validate.password[0] : ''}
                                        </div>
                                    </div>
                                    </div>

                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary w-100 theme-btn mx-auto">Reset Password</button>
                                </div>
                            </form>

                            {/* <hr /> */}
                            {/* <div className="auth-option text-center pt-2"><Link className="text-link" to="/login" >Back to Login</Link></div> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Reset;