import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Login from "./components/views/Login";
// import Register from "./components/views/Register";
import Forgot from "./components/views/Forgot";
import Reset from "./components/views/Reset";

const Auth = () => {
  return (
    <Router>
      <Switch>
        {/* <Route path='/login' component={Login} />
        <Route path='/register' component={Register} /> */}
        <Route path='/forgot-password' component={Forgot} />
        <Route path='/reset-password' component={Reset} />
        <Route path='/' component={Forgot} />
      </Switch>
    </Router>
  );
}

export default Auth;
